import * as React from 'react';
import {B2CComponentsProvider, SSOPage} from '@edekadigital/b2c-components';
import {Header, Footer} from '../../components';
import LegalDocument from '../../components/commons/LegalDocument';
import EdekaTitle from '../../components/commons/Title';

export const Head = EdekaTitle;
const Datenschutz: React.FC = () => {
  const header = <Header />;
  const footer = <Footer />;
  return (
    <B2CComponentsProvider>
      <SSOPage header={header} footer={footer}>
        <LegalDocument type={'privacy-policy'} />
      </SSOPage>
    </B2CComponentsProvider>
  );
};

export default Datenschutz;
